
/* Main SCSS file
 * Includes all of the style components
 */

/* Global variables */
$icon-font-path: "/modules/bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "/modules/font-awesome/fonts";

/* No Bootstrap rounded corners */
$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

/* Import external frameworks */
@import "../../node_modules/slidey-pane/styles/sass/sizes";
@import "../../node_modules/slidey-pane/styles/sass/styles";
@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../node_modules/ui-select/dist/select";
@import "../../node_modules/font-awesome/scss/font-awesome";

/* Import custom generic classes */
@import "generic/borderless";
@import "generic/center";
@import "generic/font-size";
@import "generic/font-weight";
@import "generic/spacing";
@import "generic/overflow";
@import "generic/clickable";
@import "generic/background";
@import "generic/inline-block";
@import "generic/secondary-text";
@import "generic/transition";
@import "generic/vertical-align";
@import "generic/whitespace";

/* Import custom framework extensions */
@import "extensions/btn";
@import "extensions/btn-group";
@import "extensions/checkbox";
@import "extensions/dropdown-menu";
@import "extensions/form-group";
@import "extensions/input-fixed";
@import "extensions/input-group";
@import "extensions/label";
@import "extensions/navbar";
@import "extensions/panel";
@import "extensions/radio-pair";
@import "extensions/row-fluid";
@import "extensions/ui-select";

/* Import custom components */
@import "components/action-header";
@import "components/actions";
@import "components/add-course";
@import "components/box";
@import "components/cleanup-options";
@import "components/cleanup-panel";
@import "components/cleanup-table";
@import "components/contact";
@import "components/course-line";
@import "components/course-select-header";
@import "components/danger-header";
@import "components/enrolment";
@import "components/export";
@import "components/flag-banner";
@import "components/flag-btn";
@import "components/info-header";
@import "components/query-builder";
@import "components/parentform";
@import "components/pickable-calendar";
@import "components/menu-bar";
@import "components/student-feed";
@import "components/tabular";
@import "components/performance-indicator";
@import "components/student-list";
@import "components/student-listing-checkbox";
@import "components/user-list";
@import "components/work-habit";
@import "components/work-habits";
