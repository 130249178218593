
/* Extensions to the bootstrap .panel class
 * Enable display of pre-wrapped content
 */

p.panel-pre {
  white-space: pre-wrap;
  line-height: 16px;
  margin-bottom: 0;
}

.panel-with-buttons {
  display: flex;
  align-items: top;
  justify-content: space-between;
}

.button-container {
  display: flex;
  align-items: center;
  height: 14px;
}

.panel-icon {
  font-size: 19px;
  color: gray;
}
