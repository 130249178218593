
/* Center
 * Provide basic centering with flexbox
 */

.vertical-center {
  min-height: 100%;       // Fallback for browsers that do not support vh
  min-height: 100vh;
  -ms-flex-pack: center;  // IE10
  -ms-flex-align: center; // IE10
  display: -ms-flexbox;   // IE10
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.vertical-center--full-height {
  height: 100%; // Fallback for browsers that do not support vh
  height: 100vh;
}
