
/* Box
 * Defines a box
 */

.box {
  background-color: #fff;
}

  .box--bordered {
    border: 1px solid #D1D1D1;
  }

  .box--setting {
    @extend .box--bordered;
    white-space: pre-wrap;
    padding: 5px;
    width: 50%;
  }

  .box--disabled {
    @extend .box--setting;
    background-color: #eee;
    font-style: italic;
  }
