
/* User list
 * Styles for the admin user list
 */

.userlist {
}

  .userlist--row {
    border-top: 0;
    border-bottom: 1px solid #ddd;
  }

  .userlist--row td {
    line-height: 30px !important;
    border-top: 0 !important;
  }
