
/* Pickable Calendar
 * Calendar styles using angular-date-pickable
 */

.pickable__calendar {
  border-collapse: separate;
  border-spacing: 10px;
  position: relative;
  margin-top: 24px;
  width: 100%;
}

.pickable__calendar th {
  text-align: center;
}

.pickable__calendar td {
  background-color: #E8E8E8;
  border: 1px solid gray;
  text-align: center;
  cursor: pointer;
}

.pickable__calendar td:hover {
  background-color: #337AB7;
}

.pickable__day--selected {
  background-color: #286090 !important;
  color: white;
}
