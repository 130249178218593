
/* Spacing
 * Basic spacing helpers
 * TODO: These do not align with bootstrap defaults.
 */

.margin { margin: 15px; }
.margin--top { margin-top: 15px; }
.margin--left { margin-left: 15px; }
.margin--right { margin-right: 15px; }
.margin--bottom { margin-bottom: 15px; }
.margin--horizontal { margin-left: 15px; margin-right: 15px; }
.margin--vertical { margin-top: 15px; margin-bottom: 15px; }

.margin-half { margin: 7px; }
.margin-half--top { margin-top: 7px; }
.margin-half--left { margin-left: 7px; }
.margin-half--right { margin-right: 7px; }
.margin-half--bottom { margin-bottom: 7px; }
.margin-half--horizontal { margin-left: 7px; margin-right: 7px; }
.margin-half--vertical { margin-top: 7px; margin-bottom: 7px; }

.margin-none { margin: 0px; }
.margin-none--top { margin-top: 0px; }
.margin-none--left { margin-left: 0px; }
.margin-none--right { margin-right: 0px; }
.margin-none--bottom { margin-bottom: 0px; }
.margin-none--horizontal { margin-left: 0px; margin-right: 0px; }
.margin-none--vertical { margin-top: 0px; margin-bottom: 0px; }

.padding { padding: 15px; }
.padding--top { padding-top: 15px; }
.padding--left { padding-left: 15px; }
.padding--right { padding-right: 15px; }
.padding--bottom { padding-bottom: 15px; }
.padding--horizontal { padding-left: 15px; padding-right: 15px; }
.padding--vertical { padding-top: 15px; padding-bottom: 15px; }

.padding-half { padding: 7px; }
.padding-half--top { padding-top: 7px; }
.padding-half--left { padding-left: 7px; }
.padding-half--right { padding-right: 7px; }
.padding-half--bottom { padding-bottom: 7px; }

.padding-none { padding: 0px; }
.padding-none--top { padding-top: 0px; }
.padding-none--left { padding-left: 0px; }
.padding-none--right { padding-right: 0px; }
.padding-none--bottom { padding-bottom: 0px; }
