
/* Bootstrap checkbox extensions
 */

/* Align properly in horizontal forms
 */

.checkbox--full-height {
  margin-top: 27px;
  margin-bottom: 27px;
}

/* Alignment fix for the compose email screen
 */

.checkbox--compose {
  margin-top: 18px;
}
