
/* Bootstrap button extensions
 */

/* Force Bootstrap buttons with text alignment to actually align
 * Expands Bootstrap's alignment classes
 */

input[type="button"].text-left, button.text-left {
  text-align: left !important;
}

input[type="button"].text-right, button.text-right {
  text-align: right !important;
}

/* Buttons without labels should have a top margin
 * That way the button still aligns in the form
 */

.btn.btn-labelless {
  margin-top: 25px;
}

/* Add a hollow class style
*/

.btn.btn-hollow {
  border: 1px solid #979797;
  background: transparent;
}

.btn.btn-hollow:hover {
  background: rgba(0, 0, 0, 0.3);
  color: inherit;
}

.btn.btn-hollow:focus {
  color: inherit;
}

.btn.has-error {
  border-color: $state-danger-text;
}
