
/* Query Builder
 * Styles for the query builder interface
 */

.query-builder {
  background-color: #f8f8f8;
  border: 1px solid #efefef;
  padding: 15px 15px;
}
