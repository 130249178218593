
/* Info header
 * Creates a brown-header panel section
 */

.info-header {
  color: white;
  background: #383736;

  .fa {
    width: 20px;
  }
}
