.student-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.student-list__student {
  flex: 2 2 225px;
}

.student-list__date {
  flex: 1 0 125px;
}

.student-list__grade {
  flex: 0 0 150px;
}

.student-list__school {
  flex: 1 1 150px;
  @extend .hide-ellipsis;
}

.student-list__control {
  flex: 0 0 150px;
}

  .student-list__control button {
    margin-top: -6px;
  }
