
/* Student feed
 * Styles for the student view activity feed
 */

.studentfeed {
}

  .studentfeed__title {
    padding-left: 5px; // Align with the font-awesome icons
  }

  .studentfeed__iconbg {
    color: rgba(232, 232, 232, 1);
  }

  .studentfeed__content {
    position: relative;
  }

    .studentfeed__bar {
      position: absolute;
      background-color: #f3f3f3;
      top: 10px;
      left: 27px;
      width: 4px;
      height: 100%;
      z-index: -1;
    }
