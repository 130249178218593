
/* Action buttons
 * Finer styles for the bootstrap group action buttons
 */

.actions {
  @extend .btn-group;
  @extend .btn-group--block;
  display: flex;
}

.actions__button {
  @extend .btn;
  @extend .btn-sm;
}

.actions__button--active {
  flex-grow: 1;
}
