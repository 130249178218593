
/* Evenly divided pair of two radio <div>'s
 * Expands Bootstrap's radio buttons, alternative to other form structuring
 *
 * Usage: 
 * <div class="form-group radio-pair">
 *   <div class="radio radio-pair__left">
 *     <label><input type="radio">Male</label>
 *   </div>
 *   <div class="radio radio-pair__right">
 *     <label><input type="radio">Female</label>
 *   </div>
 * </div>
 *
 */

.radio-pair {
  overflow: auto;
}

  .radio-pair__left {
    width: 50%;
    float: left;
  }

  .radio-pair__right {
    @extend .radio-pair__left;
    margin-top: 10px !important; // Override bootstrap's radio + radio rule
  }
