
/* Make a row overflow horizontally instead of overflow down a row
 * Expands Bootstrap's grid system
 *
 * Usage: 
 * <div class="row-fluid">
 *   <div class="col-md-8">
 *     <!-- content -->
 *   </div>
 *   <div class="col-md-8">
 *     <!-- content -->
 *   </div>
 * </div>
 *
 */

$grid-columns: 12 !default;

.row-fluid {
  @extend .row;
  white-space: nowrap;
}

  $list: ".row-fluid .col-xs-1";
  $list: "#{$list}, .row-fluid .col-sm-1";
  $list: "#{$list}, .row-fluid .col-md-1";
  $list: "#{$list}, .row-fluid .col-lg-1";
  @for $i from 2 through $grid-columns {
    $list: "#{$list}, .row-fluid .col-xs-#{$i}";
    $list: "#{$list}, .row-fluid .col-sm-#{$i}";
    $list: "#{$list}, .row-fluid .col-md-#{$i}";
    $list: "#{$list}, .row-fluid .col-lg-#{$i}";
  }

  #{$list} {
    overflow: hidden;
    display: inline-block;
    float: none;
  }
