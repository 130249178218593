
/* Add Course Button
 * Button for selection a new course
 */

.add-course-container {
  text-align: right;
}

.add-course {
  margin-bottom: 35px;
}
