
/* ui-select
 * Fix overflowing text and use an ellipsis
 */

.ui-select-match-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
}

.ui-select-toggle > .btn.btn-link {
  margin-right: 10px;
  top: 6px;
  position: absolute;
  right: 10px;
}
