
/* Contact
 * Defines a student contact
 */

.contact {
  display: flex;
  font-size: 16px;
  @extend .padding-half;
  position: relative;
}

.contact__star {
}

.contact__details {
  flex-wrap: wrap;
  display: flex;
  flex: 1;
}

  .contact__name {
    flex-basis: 100%;
  }

  .contact__email {
    flex-basis: 50%;
  }

  .contact__phone {
    flex-basis: 50%;
    text-align: center;
  }

.contact__edit {
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  position: absolute;
  height: 100%;
  right: 40px;
  width: 40px;
  top: 0;
}

.contact__delete {
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  position: absolute;
  height: 100%;
  width: 40px;
  right: 0;
  top: 0;
}
