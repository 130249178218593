.flag-banner {
  width: 100%;
  cursor: pointer;
  background-color: #f0ad4e;
  border-color: #eea236;
  text-align: center;
  color: white;
}

.flag-banner:hover, .flag-banner:focus {
  background-color: #ec971f;
  border-color: #d58512;
}
