.tabular {
  font-size: 14px;
  line-height: 22px;
}

.tabular__title {
  font-size: 16px;
  line-height: 20px;
}

.tabular__detail {
  font-size: 14px;
  line-height: 22px;
}

.tabular__detail--support {
  color: #9B9B9B;
}

.tabular__detail--tiny {
  font-size: 10px;
  line-height: 26px;
}

.tabular__detail--small {
  font-size: 12px;
  line-height: 24px;
}

.tabular__detail--sunk {
  line-height: 1.0;
}
