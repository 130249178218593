
/* Borderless
 * Removes a border
 */

.borderless {
  border: 0 !important;
}

  .borderless--top {
    border-top: 0 !important;
  }
  .borderless--right {
    border-right: 0 !important;
  }
  .borderless--left {
    border-left: 0 !important;
  }

  .borderless--bottom {
    border-bottom: 0 !important;
  }
