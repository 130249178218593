.enrolment {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.enrolment.tabular {
  line-height: 30px;
}

.enrolment__course {
  flex: 5 5;
  flex-basis: 75px;
}

.enrolment__program {
  flex: 1 0;
  flex-basis: 40px;
}

.enrolment__date {
  cursor: pointer;
}

.enrolment__dates {
  flex: 2 0;
  flex-basis: 200px;
  display: flex;
  align-items: center;
}

  .enrolment__dates div {
    min-width: 40px;
  }

.enrolment__performance {
  flex: 2 0;
  flex-basis: 130px;
}

.enrolment__status {
  flex: 0 0;
  flex-basis: 160px;
}

.enrolment__icons {
  flex: 0 0;
  flex-basis: 50px;
  
  display: flex;
  justify-content: flex-end;
}

.enrolment__icons--myedbc {
  margin-left: 10px;
}

.enrolment__icons--myedbc .fa:hover {
  color: #286090; 
}

.enrolment__icons--course-error {
  color: #d9534f;
}
