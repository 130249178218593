
/* Extensions to the Bootstrap .input-group class
 * Variety of fixes and new functionality
 */

/* Proper bordering */
.input-group-btn button.no-right-border {
  border-right: 0;
}

/* Fix error class */
.has-error .input-group-addon {
  color: #a94442 !important;
  border-color: #a94442 !important;
  background-color: #f2dede !important;
}

/* Force similar height */
.input-group-md > .input-group-btn > .btn {
  height: 34px;
}
