
/* work-habit
 * Displays an individual work habit report
 */

.work-habit {
}

  .work-habit__header {
    font-size: 15px;
  }

  .work-habit__body {
    margin-left: 20px;
    padding-right: 40px;
    border-left: 1px solid rgba(37, 37, 37, 0.56);
    white-space: pre-wrap;
  }

  .work-habit__performance {
    padding-left: 7px;
    padding-right: 7px;
    text-align: center;
    display: inline-block;
    min-width: 40px;
  }

  .work-habit__perf--good {
    background-color: #D7EBBF;
    border: 1px solid #C4D4B2;
  }

  .work-habit__perf--satisfactory {
    background-color: #F2E9A3;
    border: 1px solid #D9D39F;
  }

  .work-habit__perf--not-satisfactory {
    background-color: #F6C4CB;
    border: 1px solid #DCB7BC;
  }

  .work-habit__perf--none {
    background-color: #eeeeee;
    border: 1px solid #cccccc;
  }
