.flag-btn {
  @extend .btn;
  @extend .btn-block;
  @extend .margin--top;
  padding: 15px 40px;
  display: flex;
}

.flag-btn:not(.flag-btn--isActive) {
  @extend .btn-default;
}

.flag-btn--isActive {
  background-color: #f0ad4e;
  border-color: #eea236;
  color: white;
}

.flag-btn--isActive:hover, .flag-btn--isActive:focus {
  background-color: #ec971f;
  border-color: #d58512;
  color: white;
}

  .flag-btn__text {
    flex-grow: 1;
    font-size: 25px;
    text-align: center;
    line-height: 42px;
  }
