
/* Cleanup Table
 * Shows what will and will not be deleted
 */

.cleanup-table {
  display: flex;
  @extend .margin--top;
}

  .cleanup-table__column {
    width: 50%;
  }

    .cleanup-table__text--retained {
       color: green;
    }

    .cleanup-table__text--deleted {
       color: red;
    }

    .cleanup-table__column > ul {
      margin-left: -20px;
    }
