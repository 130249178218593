
/* Cleanup Options
 * Shows cleanup job options
 */

.cleanup-options {
  @extend .text-center;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
}

  .cleanup-options__option {
    width: 350px;
    border: 1px solid gray;
    background-color: white;
    @extend .padding;
  }
