
/* Fixed input box text label
 * Expands Bootstrap's input-group
 *
 * Usage: 
 * <div class="input-group">
 *   <span class="input-group-addon">Test</span>
 *   <label class="input-fixed">Other:</label>
 *   <input type="text" class="form-control input-fixed__target">
 * </div>
 *
 */

.input-group .input-fixed {
  position: absolute;
  z-index: 999;
  left: 45px;
  top: 8px;
}

.input-group .input-fixed__target {
  padding-left: 55px;
}
