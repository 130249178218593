
/* Export
 * Export job component
 */

.export {
  margin-top: 35px;
}

  .export__title {
    display: inline-block;
    font-size: 13pt;
    color: #606062;
    width: 250px;
  }

  .export__date {
    display: inline-block;
    width: 130px;
  }

  .export--inprogress .export__date {
    width: 300px;
  }

  .export__button {
    padding-right: 30px;
    padding-left: 30px;
  }

  .export__description {
    color: rgba(60, 60, 60, 1);
  }

  .export__link {
    text-decoration: underline;
  }
