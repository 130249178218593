
/* Overflow hiding helper
 */

.hide-overflow {
  overflow: hidden;
}

.hide-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hide-wrap {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
