
/* Extensions to the Bootstrap .btn-group class
 * Adds full width support and let's us mark it as invalid
 */

/* btn-group--block */

.btn-group--block {
  width: 100%;
}

/* btn-group-toggle */

.btn-group-toggle {
  width: 100%;
}

  .btn-group-toggle button {
    width: 25%;
  }

  .btn-group-toggle button.btn-group-toggle--selected {
    width: 75%;
  }

/* Misc */

.btn-group.has-error button {
  border-color: $state-danger-text;
}

.btn-group button.no-right-border {
  border-right: 0;
}
