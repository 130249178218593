
/* Cleanup Panel
 * Confirms and initiates a cleanup job
 */

.cleanup-panel {
  display: flex;
  justify-content: center;
}

  .cleanup-panel__container {
    width: 520px;
  }

  .cleanup-panel__buttons {
    position: relative;
    @extend .text-center;
  }

    .cleanup-panel__buttons > .btn-link {
      position: absolute; 
      left: 0;
    }
