
/* Parent form
 * Form styles for the parent report card interface */

.parentform {
  width: 300px;
}

  .parentform__title {
    margin-bottom: 40px;
  }

  .parentform__button {
    width: 200px;
    line-height: 1.5em;
  }
