
/* Action header
 * Creates a blue-header panel section
 */

.action-header {
  color: white;
  background: #105791;
  padding-left: 8.33333%; /* Match bootstrap 1col padding */
}

.action-header--error {
  color: white;
  background: #d9534f;
  padding-left: 8.33333%; /* Match bootstrap 1col padding */
}

.action-header__icon {
  margin-right: 10px;
}