
/* Font size
 * Basic text size controls
 */

$font-size-base: 14px !default;

.text-default {
  font-size: $font-size-base;
}

.line--single {
  line-height: 1em;
}

.line--double {
  line-height: 2em;
}
