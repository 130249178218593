
/* work-habits
 * Displays a previous work habits and a form for creating new ones
 */

.work-habits {
  background-color: #daebff;
  padding-right: 15px;
}

  .work-habits__comment {
    min-height: 100px;
    width: 100%;
  }

  .work-habits__comment:not(.ng-invalid) {
    border: 1px solid #efefef;
  }
