
/* Menu bar
 * Creates a SMS menu header bar
 */

.menu-bar {
  background-color: rgb(74, 74, 74);
  line-height: 33px;
  color: white;
}

// Realign font-awesome stacks
.menu-bar .fa-stack, .menu-bar .fa-stack-2x {
  line-height: inherit;
}
