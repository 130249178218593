
/* Secondary text
 * Non-primary text coloring
 */

.text-secondary {
  color: gray;
}

.text-secondary strong {
  color: black;
}

.label .text-secondary {
  color: #E6E6E6;
}
